import React from "react";
import { useAuthenticated } from "react-admin";
import { Button } from "@material-ui/core";

function CollegeImages() {
  useAuthenticated();
  return <div>notis</div>;
}

export default CollegeImages;
