import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  EmailField,
  NumberField,
  ImageField,
  DateField,
  RichTextField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { TimeField } from "../../components/common/TimeField";
import {
  CollegesReviewed,
  CollegesVisited,
  SubscriptionQuery,
  Subscriptions,
  ContactUsQuery,
} from "./UserList";

const useStyles = makeStyles({
  root: {
    display: "flex",
    "& img": {
      maxHeight: "5rem",
    },
  },
  innerContainer: {
    padding: 16,
  },
});

export const UserShow = (props) => {
  const classes = useStyles();
  // console.log("PROPS", props);
  return (
    <Show {...props}>
      <SimpleShowLayout className={classes.innerContainer}>
        <TextField label="User Info Id" name={"Id"} source="id" />
        <TextField name={"Name"} source="name" />
        <EmailField label="Email address" name={"Email"} source="email" />
        <NumberField label="Phone" source="phone" />
        <TextField label="Attributes" source="attributes" />
        <TextField label="User Id" source="user_id" />
        <TextField label="Has Rated" source="rated" />
        <TextField label="Rating Feedback" source="rating_feedback" />
        <ImageField
          className={classes.root}
          label="Image"
          name={"img_url"}
          source="img_url"
        />
        <TextField label="Created At" />
        <TimeField label="Created" name={"Created on"} source="createdAt" />
        <TextField label="Updated At" />
        <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
        <TextField label="Colleges Visited" />
        <CollegesVisited
          name="Colleges Visited"
          label="Colleges Visited"
          source="collegesVisited"
        />
        <TextField label="Subscriptions" />
        <Subscriptions label="Subscriptions" source="subscribedPlans" />
        <TextField label="Subscription Query" />
        <SubscriptionQuery
          label="Subscription Query"
          source="subscriptionQuery"
        />
        <TextField label="Contact Us Query" />
        <ContactUsQuery label="Contact Us Query" source="userQuery" />
        <TextField label="Colleges Reviewed" />
        <CollegesReviewed label="Colleges Reviewed" source="collegesReviewed" />
      </SimpleShowLayout>
    </Show>
  );
};
