import React from "react";
import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const GeneralQueryEdit = (props) => {
  return (
    <>
      <h2>Edit General Query</h2>
      <Edit {...props} title={<PostTitle />}>
        <SimpleForm title="Edit Query">
          <TextInput source="name" required fullWidth />
          <TextInput source="email" fullWidth />
          <TextInput source="phone" fullWidth />
          <TextInput source="attributes" required fullWidth multiline />
        </SimpleForm>
      </Edit>
    </>
  );
};
