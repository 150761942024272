import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  RichTextField,
  DateTimeInput,
  useRecordContext,
  SelectInput,
} from "react-admin";
import { COUPON_TYPES, DISCOUNT_TYPES, STATUS } from "./CouponCreate";

const RecordTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.coupon_name}` : ""}</span>;
};

export const CouponEdit = (props) => {
  return (
    <>
      <h2>Edit Coupon</h2>
      <Edit {...props} title={<RecordTitle />}>
        <SimpleForm title="Edit Coupon">
          <TextInput source="coupon_name" fullWidth />
          {/* <TextInput source="coupon_type" fullWidth /> */}
          <SelectInput
            source="coupon_type"
            choices={COUPON_TYPES}
            optionText="name"
            optionValue="value"
            required
          />
          <TextInput source="coupon_code" fullWidth />
          {/* <TextInput source="discount_type" fullWidth /> */}
          <SelectInput
            source="discount_type"
            choices={DISCOUNT_TYPES}
            optionText="name"
            optionValue="value"
            required
          />
          <TextInput source="discount" fullWidth />
          {/* <TextInput source="status" fullWidth /> */}
          <SelectInput
            source="status"
            choices={STATUS}
            optionText="name"
            optionValue="value"
            required
          />
          <TextInput source="subscription_plan" fullWidth />
          <DateTimeInput source="start_date" fullWidth />
          <DateTimeInput source="end_date" fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};
