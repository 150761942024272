import React, { useState, useEffect } from "react";
import { useAuthenticated, useNotify, Button } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import dataProvider from "../../utils/apiService";
import Grid from "@material-ui/core/Grid";
import ListSubheader from "@material-ui/core/ListSubheader";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
// import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import Upload from "./Upload";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    // width: 500,
    // height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const Images = ({ title, resourceName }) => {
  useAuthenticated();
  const classes = useStyles();
  const notify = useNotify();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteTitle, setDeleteTitle] = useState(null);

  const loadImages = () => {
    dataProvider
      .get(`images/${resourceName}`)
      .then((res) => {
        // console.log(res);
        setData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const deleteImage = (id) => {
    dataProvider
      .delete(`images/${resourceName}`, { id: id })
      .then(({ data }) => {
        // console.log("deleteImage data ::::", data);
        loadImages();
        notify(`Image deleted successfully - ${deleteTitle}`, "success");
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        notify(
          `Error deleting image - ${deleteTitle} ${error.body.error}`,
          "warning"
        );
        setLoading(false);
      });
  };

  const handleDeleteCancel = () => {
    setOpen(false);
  };
  const handleDeleteOk = () => {
    setOpen(false);
    deleteImage(deleteId);
  };

  const openDeleteDialog = (id, title) => {
    setDeleteTitle(title);
    setDeleteId(id);
    setOpen(true);
  };
  useEffect(() => {
    loadImages();
  }, []);
  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          Delete {deleteTitle} ?
        </DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete this image?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container style={{ padding: 16 }}>
        <Upload afterUploadCallback={loadImages} resourceName={resourceName} />

        <Grid item xs={12}>
          <ListSubheader component="div">{title}</ListSubheader>
        </Grid>
        <Grid container justifyContent="center" spacing={3}>
          {data &&
            data.map((item) => (
              <Grid
                key={item.id}
                xs={12}
                md={3}
                alignItems="center"
                justifyContent="flex-start"
                style={{ position: "relative" }}
                item
              >
                {/* <Grid item direction="row" style={{ position: "relative" }}> */}
                <img
                  src={item.url}
                  alt={item.title}
                  style={{ height: "auto", width: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 15,
                    backgroundColor: "#00000040",
                    borderRadius: "50%",
                    width: 40,
                    height: 40,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // opacity: 0.3,
                  }}
                >
                  <Button
                    aria-label={`info about ${item.title}`}
                    className={classes.icon}
                    onClick={() => openDeleteDialog(item.id, item.title)}
                  >
                    <DeleteIcon
                      style={{
                        width: 24,
                        height: 24,
                        marginLeft: 10,
                      }}
                    />
                  </Button>
                </div>
                <ImageListItemBar
                  style={{
                    position: "absolute",
                    bottom: 0,
                    padding: -4,
                    marginLeft: 15,
                    marginRight: 15,
                    marginBottom: 25,
                  }}
                  title={item.title}
                  subtitle={
                    <span>
                      uploaded :{" "}
                      {`${new Date(item.createdAt).toLocaleDateString()}
                       (${new Date(item.createdAt).toLocaleTimeString()})`}
                    </span>
                  }
                  actionIcon={
                    <Button
                      aria-label={`info about ${item.title}`}
                      className={classes.icon}
                      onClick={() => {
                        navigator.clipboard.writeText(item.url);
                      }}
                    >
                      <FileCopyIcon />
                    </Button>
                  }
                />
                {/* </Grid> */}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Images;
