import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  RichTextField,
  useEditContext,
  useRecordContext,
  useEditController,
} from "react-admin";
import SendDialog from "./SendDialog";
import Box from "@material-ui/core/Box";

const PostTitle = ({}) => {
  const record = useRecordContext();
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const NewsEdit = (props) => {
  // const { record, isLoading } = useEditContext();
  const { record, save, isLoading } = useEditController({
    resource: "newsnotification",
    id: props.id,
  });
  if (isLoading) return null;
  console.log("props::::", record);
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <h2>Edit News</h2>
        </div>
        <div>
          <SendDialog data={record} />
        </div>
      </Box>
      <Edit {...props} title={<PostTitle />}>
        <SimpleForm title="Edit Query">
          <TextInput source="title" required fullWidth />
          <TextInput source="description" fullWidth multiline />
          <TextInput source="source" fullWidth />
          <TextInput source="source_url" fullWidth />
          <TextInput source="image" fullWidth />
          <TextInput source="video" fullWidth />
        </SimpleForm>
        {/* <SendDialog /> */}
      </Edit>
      {/* <SendDialog /> */}
    </>
  );
};
