import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
  DateTimeInput,
} from "react-admin";

export const LAYOUT_TYPES = [
  {
    name: "HOME",
    value: "HOME",
  },
];

export const STATUS = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

export const DyanamicLayoutCreate = (props) => {
  return (
    <>
      <h1>New Dyanamic Layout</h1>
      <Create {...props} title="Create A New Dyanamic Layout">
        <SimpleForm title="New Dyanamic Layout">
          <SelectInput
            source="layout_type"
            choices={LAYOUT_TYPES}
            optionText="name"
            optionValue="value"
            required
          />
          <TextInput source="site" required fullWidth />
          <TextInput source="data" fullWidth multiline />
          {/* <SelectInput
            source="active"
            choices={STATUS}
            optionText="name"
            optionValue="value"
            required
          /> */}
        </SimpleForm>
      </Create>
    </>
  );
};
