import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  List,
} from "react-admin";

export const GeneralQueryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
  </Filter>
);
