import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import dataProvider from "../../utils/apiService";
import { useNotify, useRedirect, Button } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import CardMedia from "@material-ui/core/CardMedia";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles({
  root: {
    display: "flex",
    "& img": {
      maxHeight: "5rem",
    },
  },
  inline: {
    display: "inline",
  },
  media: {
    height: 50,
    width: 100,
    padding: "0.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    // paddingTop: "56.25%", // 16:9
  },
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function DataItem({ dataItem, index, handleDelete }) {
  const classes = useStyles();
  return (
    <Draggable draggableId={`${dataItem.id}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <CardMedia
                className={classes.media}
                image={dataItem.imgurl}
                title={dataItem.title}
              />
              {/* <ImageListItem alt="Remy Sharp" image={dataItem.imgurl} /> */}
            </ListItemAvatar>
            <ListItemText
              primary={dataItem.title}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {dataItem.createdAt}
                  </Typography>
                  {` - ${dataItem.redirect_url}`}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction onClick={() => handleDelete(dataItem.id)}>
              {/* <Button edge="end" aria-label="delete"> */}
              <DeleteIcon />
              {/* </Button> */}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="middle" />
        </div>
      )}
    </Draggable>
  );
}

const DataList = React.memo(function DataList({ list, handleDelete }) {
  return list.map((listItem, index) => (
    <List key={`list_${index}`}>
      <DataItem
        dataItem={listItem}
        index={index}
        handleDelete={handleDelete}
        key={`carous_${listItem.id}`}
      />
    </List>
  ));
});

function Carousels() {
  const redirect = useRedirect();
  const notify = useNotify();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const loadData = () => {
    dataProvider
      .get(`carousels`)
      .then((res) => {
        // console.log(res);
        setData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const list = reorder(data, result.source.index, result.destination.index);

    const updatedDataWithRank = list.map((dataItem, index) => ({
      ...dataItem,
      rank: index + 1,
    }));

    setData(updatedDataWithRank);
  }

  const submitData = () => {
    dataProvider
      .post(`carousels/rank`, { data: { carouselsWithIdAndRank: data } })
      .then((res) => {
        // show success message
        notify("Successfully saved", "success");
      })
      .catch((error) => {
        // show error message
        notify(`Error: ${error.message}`, "warning");
      });
  };

  const handleDelete = (id) => {
    dataProvider.delete(`carousels`, { id: id }).then((res) => {
      // show success message
      notify("Successfully deleted", "success");
      loadData();
    });
  };

  const [open, setOpen] = React.useState(false);
  const [selectedDataId, setSelectedDataId] = React.useState();

  const handleClickOpen = (id) => {
    setSelectedDataId(id);
    setOpen(true);
  };

  const handleDeleteCancel = () => {
    setOpen(false);
  };

  const handleDeleteOk = () => {
    setOpen(false);
    handleDelete(selectedDataId);
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">Delete ?</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete this carousel?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="text"
        style={{ alignSelf: "flex-end" }}
        color="primary"
        onClick={() => redirect("/carousels/create")}
      >
        + Create
      </Button>
      {data && data.length && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <DataList list={data} handleDelete={handleClickOpen} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            variant="contained"
            style={{ fontSize: 8, alignSelf: "center" }}
            color="primary"
            onClick={submitData}
          >
            Submit
          </Button>
        </>
      )}
    </>
  );
}

export default Carousels;
