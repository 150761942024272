import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  SelectInput,
  NumberField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { PdfFilter } from "./PdfFilter";
import { CustomActiveField } from "../DyanamicLayout/DyanamicLayoutList";
import { PartialTextField } from "../../components/common/PartialTextField";

export const PdfList = (props) => (
  <List
    {...props}
    filters={<PdfFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Pdf Title" name={"title"} source="title" />
      <PartialTextField
        maxLength={250}
        style={{ width: 300, overflowWrap: "break-word" }}
        label="Pdf Url"
        name={"url"}
        source="url"
      />

      <CustomActiveField
        label="Is Downloadable"
        name={"download"}
        source="download"
      />

      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
