import { AppSettings } from "../config/appSettings";
import decodeJwt from "jwt-decode";

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(
      `${AppSettings.server.baseUrl}/api/v1/auth/login`,
      {
        method: "POST",
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        console.log("auth service response", res);
        const decodedToken = decodeJwt(res.token);
        localStorage.setItem("token", res.token);
        localStorage.setItem("role", decodedToken.role);
      });
  },

  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403 || status === 500) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),

  getPermissions: (params) => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
