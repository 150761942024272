import React from "react";
import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";

const NotificationTagTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const NotificationTagEdit = (props) => {
  return (
    <>
      <h2>Edit Notification Tag</h2>
      <Edit {...props} title={<NotificationTagTitle />}>
        <SimpleForm title="Edit Notification Tag">
          <TextInput source="tag_id" label="Tag Id" required fullWidth />
          <TextInput source="title" required fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};
