import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
} from "react-admin";

import { TimeField } from "../../components/common/TimeField";
import SubscriptionQueryFilter from "./SubscriptionQueryFilter";

import { UserInfo } from "../../components/common/UserInfo";

export const SubscriptionQueryList = (props) => {
  return (
    <List
      {...props}
      filters={<SubscriptionQueryFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Id" source="id" />
        <UserInfo
          sortable={false}
          {...props}
          label="User Id"
          source="user_id"
        />
        {/* <LinkedField label="Userd" source="user_id" /> */}
        {/* <TextField label="User Id" source="user_id" /> */}
        <TextField label="Query Type" source="type" />
        <TextField label="Query Message" source="query" />
        <TextField label="Resolved" source="resolved" />
        <TextField label="Order Id" source="order_id" />
        <TextField label="Payment Id" source="payment_id" />
        <TextField label="PlanId" source="plan_id" />
        <NumberField label="Phone" source="phone" />
        <EmailField label="Email" source="email" />

        <TimeField label="Created" name={"Created on"} source="createdAt" />
        <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
      </Datagrid>
    </List>
  );
};
