import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { SimpleForm, TextInput, Create } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export default function BasicTextFields() {
  const classes = useStyles();

  return (
    <>
      <h1>Create New Carousel</h1>
      <Create
        title="Create a new Carousel"
        basePath="/carousels"
        resource="carousels"
      >
        <SimpleForm>
          <TextInput source="title" required />
          <TextInput source="imgurl" required />
          <TextInput source="redirect_url" />
          {/* <TextInput source="type" />
          <TextInput source="extras" options={{ multiLine: true }} /> */}
        </SimpleForm>
      </Create>
    </>
  );
}
