import React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>{record ? `${record.name} (${record.role})` : ""}</span>;
};

export const SubscriptionQueryEdit = (props) => {
  const options = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
  ];

  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          title="Is Resolved ?"
          source="resolved"
          choices={options}
          optionText="name"
          optionValue="value"
        />
      </SimpleForm>
    </Edit>
  );
};
