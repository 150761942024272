import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { PartialTextField } from "../../components/common/PartialTextField";
import { BlogFilter } from "../index";

export const BlogList = (props) => (
  <List
    {...props}
    filters={<BlogFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Title" name={"title"} source="title" />
      <PartialTextField
        maxLength={200}
        style={{ width: 300 }}
        label="Description"
        name={"description"}
        source="description"
      />
      <TextField label="HTML" name={"html"} source="html" />
      <TextField label="URL" name={"url"} source="url" />
      <TextField label="Img Url" name={"img_url"} source="img_url" />
      <TextField label="Slug" name={"slug"} source="slug" />
      {/* <TextField
        label="JSON Content"
        name={"json_content"}
        source="json_content"
      /> */}
      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
