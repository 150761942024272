import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { TimeField } from "../../components/common/TimeField";

export const TermsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Id" name={"Id"} source="id" />
      <TextField name={"Title"} source="title" />
      <TextField name={"Description"} source="description" />
      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
