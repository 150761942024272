import React from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";

import Folders from "./pages/Images/Folders";
import Images from "./pages/Images/Images";
import Carousels from "./pages/Carousels/Carousels";
import CollegeLogoImages from "./pages/Images/CollegeLogos";
import CollegeImages from "./pages/Images/Colleges";
import CarouselCreate from "./pages/Carousels/CarouseCreate";
import SendNotification from "./pages/SendNotification/SendNotification";

import {
  TermsList,
  TermsCreate,
  TermsEdit,
  BlogList,
  BlogEdit,
  BlogCreate,
  CutoffJsonList,
  CutoffJsonEdit,
  CutoffJsonCreate,
  PlacementList,
  PlacementEdit,
  PlacementCreate,
  SeatmatrixList,
  SeatmatrixEdit,
  SeatmatrixCreate,
  GeneralQueryList,
  GeneralQueryEdit,
  NewsList,
  NewsEdit,
  NewsCreate,
  CollegeList,
  CollegeEdit,
  NotificationTagCreate,
  NotificationTagEdit,
  NotificationTagList,
  NotificationCategoryCreate,
  NotificationCategoryEdit,
  NotificationCategoryList,
  NotificationList,
  NotificationEdit,
  NotificationCreate,
  UserList,
  UserListRestricted,
  UserShow,
  UserQueryList,
  UserQueryEdit,
  SubscriptionQueryList,
  SubscriptionQueryEdit,
  SuggestionList,
  SuggestionEdit,
  SubscriptionList,
} from "./pages";

import { PdfList } from "./pages/Pdf/PdfList";
import { PdfCreate } from "./pages/Pdf/PdfCreate";
import { PdfEdit } from "./pages/Pdf/PdfEdit";
import { PdfFilter } from "./pages/Pdf/PdfFilter";
// import { UserList } from "./pages/Users/UserList";
// import UserListRestricted from "./pages/Users/UserListRestricted";
// import { UserShow } from "./pages/Users/UserShow";
// import { UserEdit } from "./pages/Users/UserEdit";

// import UserQueryList from "./pages/UserQuery/UserQueryList";
// import UserQueryEdit from "./pages/UserQuery/UserQueryEdit";

// import SubscriptiolnQueryList from "./pages/SubscriptionQuery/SubscriptionQueryList";
// import SubscriptionQueryEdit from "./pages/SubscriptionQuery/SubscriptionQueryEdit";

// import SuggestionList from "./pages/Suggestion/SuggestionList";
// import SuggestionEdit from "./pages/Suggestion/SuggestionEdit";

// import SubscriptionList from "./pages/Subscription/SubscriptionList";

import UserIcon from "@material-ui/icons/Person";
import PlacementIcon from "@material-ui/icons/School";
import BlogIcon from "@material-ui/icons/Store";
import TncIcon from "@material-ui/icons/FileCopy";
import QueryIcon from "@material-ui/icons/Feedback";
import NewsIcon from "@material-ui/icons/NewReleases";
import CollegeIcon from "@material-ui/icons/Apartment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LabelIcon from "@material-ui/icons/Label";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
// import jsonServerProvider from "ra-data-json-server";
import authProvider from "./utils/authService";
import dataProvider from "./utils/apiService";
import { theme } from "./theme/theme";
// import CustomRoutes from "./CustomRoutes";
import Menu from "./Menu";
import {
  AddToQueue,
  LocalOffer,
  TagFaces,
  Notifications,
} from "@material-ui/icons";

import { CouponList } from "./pages/Coupons/CouponList";
import { CouponEdit } from "./pages/Coupons/CouponEdit";
import { CouponCreate } from "./pages/Coupons/CouponCreate";

import { DyanamicLayoutList } from "./pages/DyanamicLayout/DyanamicLayoutList";
import { DyanamicLayoutEdit } from "./pages/DyanamicLayout/DyanamicLayoutEdit";
import { DyanamicLayoutCreate } from "./pages/DyanamicLayout/DyanamicLayoutCreate";

const App = () => (
  <Admin
    // customRoutes={CustomRoutes}
    dataProvider={dataProvider}
    authProvider={authProvider}
    theme={theme}
    menu={Menu}
  >
    {(permissions) => {
      return [
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="placements"
            options={{ label: "Placement Json" }}
            list={PlacementList}
            edit={PlacementEdit}
            create={PlacementCreate}
            icon={PlacementIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="blogs"
            options={{ label: "Blogs" }}
            list={BlogList}
            edit={BlogEdit}
            create={BlogCreate}
            icon={LabelIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="pdf"
            options={{ label: "PDFs" }}
            list={PdfList}
            edit={PdfEdit}
            create={PdfCreate}
            icon={PdfIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="dyanamic-layout"
            options={{ label: "Dyanamic Layout" }}
            list={DyanamicLayoutList}
            edit={DyanamicLayoutEdit}
            create={permissions === "admin" ? DyanamicLayoutCreate : null}
            icon={LabelIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="cutoffjson"
            options={{ label: "Cutoff Json" }}
            list={CutoffJsonList}
            edit={CutoffJsonEdit}
            create={CutoffJsonCreate}
            icon={AssignmentIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="notifications/filtered"
            options={{ label: "Notifications" }}
            list={NotificationList}
            edit={NotificationEdit}
            create={NotificationCreate}
            icon={Notifications}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="notification-tags"
            options={{ label: "Notification Tags" }}
            list={NotificationTagList}
            edit={permissions === "admin" ? NotificationTagEdit : null}
            create={NotificationTagCreate}
            icon={LocalOffer}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="notification-categories"
            options={{ label: "Notification Category" }}
            list={NotificationCategoryList}
            edit={permissions === "admin" ? NotificationCategoryEdit : null}
            create={NotificationCategoryCreate}
            icon={AddToQueue}
          />
        ) : null,
        permissions === "admin" ? (
          <Resource
            name="user-info/details"
            options={{ label: "User Info" }}
            list={UserList}
            permissions={permissions}
            show={UserShow}
            // edit={UserEdit}
            // create={UserCreate}
            icon={UserIcon}
          />
        ) : null,
        permissions === "admin" ? (
          <Resource
            name="user-query"
            options={{ label: "Users Query" }}
            list={UserQueryList}
            // permissions={permissions}
            edit={UserQueryEdit}
            // create={UserCreate}
            icon={UserIcon}
          />
        ) : null,
        permissions === "admin" ? (
          <Resource
            name="subscription-queries"
            options={{ label: "Subscription Query" }}
            list={SubscriptionQueryList}
            // permissions={permissions}
            edit={SubscriptionQueryEdit}
            // create={UserCreate}
            icon={UserIcon}
          />
        ) : null,
        permissions === "admin" ? (
          <Resource
            name="general-query"
            options={{ label: "General Query" }}
            list={GeneralQueryList}
            edit={GeneralQueryEdit}
            icon={QueryIcon}
          />
        ) : null,
        permissions === "admin" ? (
          <Resource
            name="suggestions"
            options={{ label: "Suggestions" }}
            list={SuggestionList}
            edit={SuggestionEdit}
            icon={QueryIcon}
          />
        ) : null,
        permissions === "admin" ? (
          <Resource
            name="subscription"
            options={{ label: "Subscriptions" }}
            list={SubscriptionList}
            // permissions={permissions}
            // edit={SubscriptionQueryEdit}
            // create={UserCreate}
            icon={LabelIcon}
          />
        ) : null,
        permissions === "client_manager" ? (
          <Resource
            name="user-info/details"
            options={{ label: "User Info" }}
            list={UserListRestricted}
            permissions={permissions}
            // edit={UserEdit}
            // create={UserCreate}
            icon={UserIcon}
          />
        ) : null,

        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="seatmatrix"
            options={{ label: "Seatmatrix Json" }}
            list={SeatmatrixList}
            edit={SeatmatrixEdit}
            create={SeatmatrixCreate}
            icon={TncIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="coupons"
            options={{ label: "Coupons" }}
            list={CouponList}
            edit={CouponEdit}
            create={CouponCreate}
            icon={LocalOffer}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="termsandconditions"
            options={{ label: "Terms & Conditions" }}
            list={TermsList}
            edit={TermsEdit}
            create={TermsCreate}
            icon={AssignmentIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="newsnotification"
            options={{ label: "News" }}
            list={NewsList}
            // show={NewsCreate}
            edit={NewsEdit}
            create={NewsCreate}
            icon={NewsIcon}
          />
        ) : null,
        permissions === "admin" || permissions === "editor" ? (
          <Resource
            name="colleges"
            options={{ label: "Colleges" }}
            list={CollegeList}
            edit={CollegeEdit}
            // create={CollegeCreate}
            icon={CollegeIcon}
          />
        ) : null,
        <CustomRoutes>
          <Route exact path="/notification" element={<SendNotification />} />
        </CustomRoutes>,
        <CustomRoutes>
          <Route exact path="/images" element={<Folders />} />
        </CustomRoutes>,
        <CustomRoutes>
          <Route
            exact
            path="/notification-images"
            element={
              <Images
                title={"Notification Images"}
                resourceName="notification-images"
              />
            }
          />
        </CustomRoutes>,
        <CustomRoutes>
          <Route
            exact
            path="/college-logo-images"
            element={
              <Images
                title={"College Logos"}
                resourceName="college-logo-images"
              />
            }
          />
        </CustomRoutes>,
        <CustomRoutes>
          <Route
            exact
            path="/college-banner-images"
            element={
              <Images
                title={"College Banners"}
                resourceName="college-banner-images"
              />
            }
          />
        </CustomRoutes>,
        <CustomRoutes>
          <Route
            exact
            path="/ads-images"
            element={<Images title={"Ads Images"} resourceName="ads-images" />}
          />
        </CustomRoutes>,
        <CustomRoutes>
          <Route
            exact
            path="/carousel-images"
            element={
              <Images
                title={"Carousel Images"}
                resourceName="carousel-images"
              />
            }
          />
        </CustomRoutes>,
        <CustomRoutes>
          <Route
            exact
            path="/misc-images"
            element={
              <Images
                title={"Miscellaneous Images"}
                resourceName="misc-images"
              />
            }
          />
        </CustomRoutes>,
        <CustomRoutes>
          <Route
            exact
            path="/carousels"
            element={
              <Carousels title={"Carousel List"} resourceName="carousels" />
            }
          />
        </CustomRoutes>,
        <CustomRoutes>
          <Route exact path="/carousels/create" element={<CarouselCreate />} />
        </CustomRoutes>,
      ];
    }}
  </Admin>
);

export default App;
