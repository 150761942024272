import React, { useState, useEffect } from "react";
// import { useForm } from "react-final-form";
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  SimpleForm,
  useGetOne,
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import IconSave from "@material-ui/icons/Save";

import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import dataProvider from "../../utils/apiService";

function SendNotificationDialog(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    title: "",
    text: "",
  });

  const { title, text } = form;
  const { data } = props;
  //   const [create, { loading }] = useCreate("posts");
  //   const notify = useNotify();
  //   const form = useForm();
  console.log(data);
  //   let record = data;
  useEffect(() => {
    if (data && data.title) {
      setForm({
        title: data.title,
        text: data.title,
      });
    }
  }, [data]);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleSubmit = () => {
    setLoading(true);
    setSaving(true);
    const obj = {
      data: {
        title: title,
        text: text,
        image: data.image,
        newsId: data.id,
      },
    };
    dataProvider.post("fcm/send", obj).then((res) => {
      setLoading(false);
      setSaving(false);
      setShowDialog(false);
      console.log("server resp:", res);
      setOpenSnackBar(true);
    });
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const onChange = (e) => {
    // console.log(e.target.name);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        label="Send Notification"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Notification</DialogTitle>

        {data && data.title && (
          <form style={{ padding: "16px" }}>
            <DialogContent>
              <Box>
                <TextField
                  label="Notification Title"
                  variant="filled"
                  value={title}
                  name="title"
                  onChange={onChange}
                  source="notification-title"
                  required
                  fullWidth
                />
              </Box>
              <Box my={6}>
                <TextField
                  label="Notification Text"
                  variant="filled"
                  value={text}
                  name="text"
                  onChange={onChange}
                  source="notification-text"
                  fullWidth
                  multiline
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
                disabled={loading}
              >
                <IconCancel />
              </Button>
              <Button
                title="Send"
                label="Send Notification"
                onClick={handleSubmit}
                disabled={loading}
              >
                <IconSave />
              </Button>
              {/* <SaveButton
                title="Send"
                label="Send Notification"
                handleSubmitWithRedirect={handleSubmit}
                //   pristine={pristine}
                //   onSave={handleSubmit}
                saving={saving}
                disabled={loading}
              /> */}
            </DialogActions>
          </form>
        )}
      </Dialog>
      <Snackbar
        // open={true}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Box
          style={{
            backgroundColor: "#e83f7d",
            // padding: "18px",
            borderRadius: "4px",
          }}
          mb={2}
          px={10}
          py={2}
        >
          Notification Sent !!!
        </Box>
      </Snackbar>
    </>
  );
}

export default SendNotificationDialog;
