import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
} from "react-admin";

export const NotificationTagCreate = (props) => {
  return (
    <>
      <h1>New Notification Tag</h1>
      <Create {...props} title="Create A New Notification Tag">
        <SimpleForm title="New Notification Tag">
          <TextInput source="tag_id" label="Tag Id" required fullWidth />
          <TextInput source="title" required fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};
