import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
} from "react-admin";

export const NotificationCategoryCreate = (props) => {
  return (
    <>
      <h1>New Notification Category</h1>
      <Create {...props} title="Create A New Notification Category">
        <SimpleForm title="New Notification Category">
          <TextInput
            source="category_id"
            label="Category Id"
            required
            fullWidth
          />
          <TextInput source="title" required fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};
