import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";

export const NotificationTagList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Tag Id" name={"tag_id"} source="tag_id" />
      <TextField label="Title" name={"title"} source="title" />
      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
