import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  List,
} from "react-admin";

export const SeatmatrixFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Institute Id" source="institute_id" alwaysOn />
  </Filter>
);
