import React, { useState, useRef } from "react";
import { Button, RefreshIconButton } from "react-admin";
import dataProvider from "../../utils/apiService";
import Grid from "@material-ui/core/Grid";
// import IconButton from "@material-ui/core/IconButton";
// import RefreshIcon from "@material-ui/icons/Refresh";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Upload = (props) => {
  const { afterUploadCallback, resourceName } = props;

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [uploadComplete, setUploadComplete] = useState(false);
  const progressInfosRef = useRef(null);

  const selectFiles = (event) => {
    setSelectedFiles(event.target.files);
    setProgressInfos({ val: [] });
  };

  //   console.log("progressInfos ::::", progressInfos);

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    try {
      await dataProvider.upload(`uploads/${resourceName}`, file, (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );

        setProgressInfos({ val: _progressInfos });
      });
      _progressInfos[idx].successMsg = "(Upload Successful)";
      _progressInfos[idx].errorMsg = "";
      setProgressInfos({ val: _progressInfos });
    } catch (err) {
      _progressInfos[idx].percentage = 0;
      _progressInfos[idx].errorMsg = err.response.data.error;
      setProgressInfos({ val: _progressInfos });
    }
  };

  const uploadFiles = () => {
    const files = Array.from(selectedFiles);

    let _progressInfos = files.map((file) => ({
      percentage: 0,
      fileName: file.name,
      errorMsg: "Uploading...",
      successMsg: "",
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises).then(() => {
      console.log("All files uploaded successfully");
      setUploadComplete(true);
      afterUploadCallback();
    });
  };

  const refreshUploads = () => {
    setSelectedFiles(null);
    setProgressInfos({ val: [] });
  };

  return (
    <Grid
      item
      xs={12}
      style={{ border: "1px solid #fff", borderRadius: "5px", padding: "10px" }}
    >
      {progressInfos && progressInfos.val.length > 0 && (
        <Box display="flex" justifyContent="flex-end">
          <RefreshIconButton
            onClick={refreshUploads}
            disabled={!uploadComplete}
          />
          {/* <RefreshIcon color="primary" />
          </Button> */}
        </Box>
      )}
      {progressInfos &&
        progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (
          <>
            <Box display="flex" alignItems="center">
              <Box width="100%" mr={1} mt={1} mb={1}>
                <Typography variant="body2" color="textSecondary">
                  {progressInfo.fileName}
                  <Typography variant="body2" color="textPrimary">
                    {progressInfo.successMsg}
                  </Typography>
                </Typography>
                {progressInfo.errorMsg && (
                  <Typography variant="body2" color="error">
                    {progressInfo.errorMsg}
                  </Typography>
                )}
                <LinearProgress
                  variant="determinate"
                  value={progressInfo.percentage}
                />
              </Box>
              <Box minWidth={35}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >{`${Math.round(progressInfo.percentage)}%`}</Typography>
              </Box>
            </Box>
          </>
        ))}

      <label className="btn btn-default p-0">
        <input type="file" multiple onChange={selectFiles} />
      </label>

      <Button
        variant="contained"
        color="primary"
        // className="btn btn-success btn-sm"
        disabled={
          !selectedFiles || (progressInfos && progressInfos.val.length > 0)
        }
        onClick={uploadFiles}
      >
        Upload
      </Button>
    </Grid>
  );
};

export default Upload;
