import React from "react";
import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const CollegeEdit = (props) => {
  return (
    <>
      <h2>Edit College</h2>
      <Edit {...props} title={<PostTitle />}>
        <SimpleForm title="Edit College">
          {/* <TextInput source="institute_id" required fullWidth /> */}
          <TextInput source="institute_name" fullWidth />
          <TextInput source="search" fullWidth />
          <TextInput source="stateId" fullWidth />
          <TextInput source="type" fullWidth />
          <TextInput source="rank" fullWidth />
          <TextInput source="img_url" fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};
