import React from "react";
import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const BlogEdit = (props) => {
  return (
    <>
      <h2>Edit Blog</h2>
      <Edit {...props} title={<PostTitle />}>
        <SimpleForm title="Edit Blog">
          <TextInput source="title" required fullWidth />
          <TextInput source="description" fullWidth multiline />
          <TextInput source="html" fullWidth multiline />
          <TextInput source="url" fullWidth />
          <TextInput source="json_content" fullWidth multiline />
          <TextInput source="img_url" fullWidth />
          <TextInput source="slug" fullWidth />
          <TextInput source="seo_attributes" fullWidth multiline />
        </SimpleForm>
      </Edit>
    </>
  );
};
