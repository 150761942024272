import React from "react";
import { useAuthenticated, useRedirect, Button } from "react-admin";

import FolderIcon from "@material-ui/icons/FolderRounded";

const FOLDERS = [
  {
    id: 1,
    name: "Notifications",
    goTo: "/notification-images",
  },
  {
    id: 2,
    name: "College Logos",
    goTo: "/college-logo-images",
  },
  {
    id: 3,
    name: "College Images",
    goTo: "/college-banner-images",
  },
  {
    id: 4,
    name: "Ads Images",
    goTo: "/ads-images",
  },
  {
    id: 5,
    name: "Carousel Images",
    goTo: "/carousel-images",
  },
  {
    id: 6,
    name: "Miscellaneous Images",
    goTo: "/misc-images",
  },
];

function Folders() {
  useAuthenticated();
  const redirect = useRedirect();

  return (
    <div>
      {FOLDERS.map((folder) => {
        return (
          <Button onClick={() => redirect(folder.goTo)}>
            <div key={folder.id}>
              <FolderIcon style={{ width: 224, height: 124 }} />
              <div>{folder.name}</div>
            </div>
          </Button>
        );
      })}
    </div>
  );
}

export default Folders;
