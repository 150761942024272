import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import SuggestionFilter from "./SuggestionFilter";

import { UserInfo } from "../../components/common/UserInfo";

export const SuggestionList = (props) => (
  <List
    {...props}
    filters={<SuggestionFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField label="Id" source="id" />
      <UserInfo sortable={false} {...props} label="User Id" source="user_id" />
      {/* <TextField label="User Id" source="user_id" /> */}
      <TextField label="Suggestion Type" source="type" />
      <TextField label="Suggestion Message" source="suggestion" />
      <TextField label="Resolved" source="resolved" />

      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
