import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
} from "react-admin";

export const BlogCreate = (props) => {
  return (
    <>
      <h1>New Blog</h1>
      <Create {...props} title="Create A New Blog">
        <SimpleForm title="New Blog">
          <TextInput source="title" fullWidth />
          <TextInput source="description" fullWidth multiline />
          <TextInput source="html" fullWidth multiline />
          <TextInput source="url" fullWidth />
          <TextInput source="json_content" fullWidth multiline />
          <TextInput source="img_url" fullWidth />
          <TextInput source="slug" fullWidth />
          <TextInput source="seo_attributes" fullWidth multiline />
        </SimpleForm>
      </Create>
    </>
  );
};
