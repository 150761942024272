import React from "react";
import moment from "moment";
import { useRecordContext } from "react-admin";

export const PartialTextField = ({ source, style, maxLength }) => {
  const record = useRecordContext();
  return (
    <div style={{ ...style }}>
      {record[source] && record[source].length > maxLength
        ? `${record[source].slice(0, maxLength - 4)} ...`
        : record[source]}
    </div>
  );
};
