import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
} from "react-admin";

export const NewsCreate = (props) => {
  return (
    <>
      <h1>New News</h1>
      <Create {...props} title="Create A New News">
        <SimpleForm title="New News">
          <TextInput source="title" required fullWidth />
          <TextInput source="description" fullWidth multiline />
          <TextInput source="source" fullWidth />
          <TextInput source="source_url" fullWidth />
          <TextInput source="image" fullWidth />
          <TextInput source="video" fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};
