// Dev Server- Put IP address of your local machine if localhost doesn't work
// const BaseUrl = "http://localhost:5000";

// Production Server
const BaseUrl = "https://preprod-api.collegetpoint.in";

let AppSettings = /** @class */ (() => {
  class AppSettings {}
  AppSettings.server = {
    baseUrl: BaseUrl,
  };
  return AppSettings;
})();
export { AppSettings };
