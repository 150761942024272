import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ReferenceField,
  useQuery,
  Loading,
  Error,
  useRecordContext,
  // useMutation,
  useUpdate,
  useGetOne,
  useDataProvider,
  Button,
} from "react-admin";
import { useMutation } from "react-query";
import { TimeField } from "../../components/common/TimeField";
import { ListItem, Divider, ListItemText, Dialog } from "@material-ui/core";

export const CollegesVisited = ({ record = {} }) => {
  // const record = useRecordContext();
  if (record && !record.length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record.map((item, index) => (
        <div>
          <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.1px solid #ffffff33",
              borderRadius: "6px",
              marginTop: 4,
            }}
          >
            <div style={{ fontSize: 12 }}>{item.institute_name}</div>
            <div
              style={{
                background: "#fff",
                color: "#000",
                fontWeight: "bold",
                fontSize: 16,
                borderRadius: 24,
                paddingLeft: 7,
                paddingRight: 7,
              }}
            >
              {item.count}
            </div>
          </ListItem>
        </div>
      ))}
    </>
  );
};

export const Subscriptions = ({ record = {} }) => {
  // const record = useRecordContext();
  if (record && !record.length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record.map((item, index) => (
        <div>
          <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.1px solid orange",
              borderRadius: "8px",
            }}
          >
            <div style={{ fontSize: 12 }}>{item.plan_id}</div>
          </ListItem>
        </div>
      ))}
    </>
  );
};

export const SubscriptionQuery = ({ record = {} }) => {
  // const record = useRecordContext();
  if (record && !record.length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record.map((item, index) => (
        <div>
          <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.1px solid #ffffff33",
              borderRadius: "6px",
              marginTop: 4,
            }}
          >
            <div
              style={{ fontSize: 12 }}
            >{`(${item.type}) : ${item.query}`}</div>
            <div
              style={{
                background: "#fff",
                color: "#000",
                fontWeight: "bold",
                fontSize: 16,
                borderRadius: 24,
                paddingLeft: 7,
                paddingRight: 7,
              }}
            >
              {item.resolved ? "Yes" : "No"}
            </div>
          </ListItem>
        </div>
      ))}
    </>
  );
};

export const ContactUsQuery = ({ record = {} }) => {
  // const record = useRecordContext();
  if (record && !record.length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record.map((item, index) => (
        <div>
          <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.1px solid #ffffff33",
              borderRadius: "6px",
              marginTop: 4,
            }}
          >
            <div
              style={{ fontSize: 12 }}
            >{`(${item.type}) : ${item.message}`}</div>
            <div
              style={{
                background: "#fff",
                color: "#000",
                fontWeight: "bold",
                fontSize: 16,
                borderRadius: 24,
                paddingLeft: 7,
                paddingRight: 7,
              }}
            >
              {item.resolved ? "Yes" : "No"}
            </div>
          </ListItem>
        </div>
      ))}
    </>
  );
};

export const CollegesReviewed = ({ record = {} }) => {
  // const record = useRecordContext();
  if (record && !record.length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record.map((item, index) => (
        <div>
          <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.1px solid #ffffff33",
              borderRadius: "6px",
              marginTop: 4,
            }}
          >
            <div
              style={{ fontSize: 12 }}
            >{`(${item.instituteName}) :: ${item.review}`}</div>
            <div
              style={{
                background: "#fff",
                color: "#000",
                fontWeight: "bold",
                fontSize: 16,
                borderRadius: 24,
                paddingLeft: 7,
                paddingRight: 7,
              }}
            >
              {item.starCount}
            </div>
          </ListItem>
        </div>
      ))}
    </>
  );
};

export const UserInfo = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  // const [data, setData] = useState(null);

  // const [mutate, { data, total, error, loading, loaded }] = useMutation({
  //   type: "getOne",
  //   resource: "user-info/details/user",
  //   payload: { id: record.user_id },
  // });
  // const [update, { data, isLoading, error }] = useUpdate({
  //   type: "getOne",
  //   resource: "user-info/details/user",
  //   payload: { id: record.user_id },
  // });
  const dataProvider = useDataProvider();
  const { mutate, isLoading, data, error } = useMutation(() =>
    dataProvider.getOne("user-info/details/user", { id: record.user_id })
  );
  // const { data, isLoading, error } = useGetOne("user-info/details/user", {
  //   id: record.user_id,
  // });
  const toggleModal = () => {
    mutate();
    setOpen((prev) => !prev);
  };
  console.log("record", data);
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  // if (!data) return null;
  // console.log("record", data);
  // if (record[source] && !record[source].length) {
  //   return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  // }

  const listItem = (key) => {
    if (key === "collegesVisited") {
      return <CollegesVisited record={data.data[key]} />;
    } else if (key === "userQuery") {
      return <ContactUsQuery record={data.data[key]} />;
    } else if (key === "subscriptionQuery") {
      return <SubscriptionQuery record={data.data[key]} />;
    } else if (key === "subscribedPlans") {
      return <Subscriptions record={data.data[key]} />;
    } else if (key === "collegesReviewed") {
      return <CollegesReviewed record={data.data[key]} />;
    } else {
      return JSON.stringify(data.data[key]);
    }
  };
  return (
    <>
      <Button
        variant="text"
        // style={{ fontSize: 8 }}
        color="primary"
        onClick={toggleModal}
        style={{ textDecoration: "underline" }}
      >
        {record.user_id}
      </Button>

      {data && (
        <Dialog open={open} onClose={toggleModal}>
          {Object.keys(data.data).map((key) => {
            return (
              <ListItem>
                <ListItemText primary={key} secondary={listItem(key)} />
              </ListItem>
            );
          })}
        </Dialog>
      )}
    </>
  );
};
