import * as React from "react";
import { createElement } from "react";
import { useMediaQuery } from "@mui/material";
import {
  DashboardMenuItem,
  Menu,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
  usePermissions,
} from "react-admin";
import Box from "@material-ui/core/Box";
import DefaultIcon from "@mui/icons-material/ViewList";
import LabelIcon from "@mui/icons-material/Label";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";

const MenuSidebar = (props) => {
  const resources = useResourceDefinitions();
  const [open] = useSidebarState();
  const { permissions } = usePermissions();
  const { onMenuClick, logout } = props;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  return (
    <Menu {...props}>
      {/* <DashboardMenuItem /> */}
      <Box style={{ marginTop: -5 }} ml={1}>
        {open ? (
          <h3
            style={{
              marginLeft: 14,
              paddingTop: -10,
              backgroundColor: "GrayText",
              textAlign: "center",
              color: "white",
              borderRadius: 34,
              padding: 2,
            }}
          >
            CollegeTpoint
          </h3>
        ) : (
          <h3
            style={{
              marginLeft: 16,
              backgroundColor: "GrayText",
              textAlign: "center",
              color: "white",
              borderRadius: 34,
              padding: 2,
            }}
          >
            C
          </h3>
        )}
        {Object.keys(resources).map((name) => (
          <MenuItemLink
            key={name}
            to={`/${name}`}
            primaryText={
              (resources[name].options && resources[name].options.label) || name
            }
            // style={{
            //   borderTop: "0.1px solid #00000033",
            //   // borderRadius: "6px",
            //   // marginTop: 4,
            // }}
            leftIcon={
              resources[name].icon ? (
                createElement(resources[name].icon)
              ) : (
                <DefaultIcon />
              )
            }
            onClick={props.onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
        {/* add your custom menus here */}
        {permissions === "admin" || permissions === "editor" ? (
          <MenuItemLink
            to="/images"
            primaryText="Images"
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ) : null}
        {permissions === "admin" || permissions === "editor" ? (
          <MenuItemLink
            to="/carousels"
            primaryText="Carousel"
            leftIcon={<ViewCarouselOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ) : null}
        {isXSmall && logout}
      </Box>
    </Menu>
  );
};
export default MenuSidebar;

// old component to be removed later

// import * as React from "react";
// import { createElement } from "react";
// import { useSelector } from "react-redux";
// import { useMediaQuery } from "@material-ui/core";
// import {
//   MenuItemLink,
//   getResources,
//   useResourceDefinitions,
//   useSidebarState,
//   usePermissions,
//   DashboardMenuItem,
//   Menu,
// } from "react-admin";
// // import { withRouter } from "react-router-dom";
// import LabelIcon from "@material-ui/icons/Label";
// import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
// import Box from "@material-ui/core/Box";

// const MenuSidebar = (props) => {
//   const { onMenuClick, logout } = props;
//   const { permissions } = usePermissions();
//   const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
//   // const open = useSelector((state) => state.admin.ui.sidebarOpen);
//   // const resources = useSelector(getResources);
//   const resources = useResourceDefinitions();
//   const [open] = useSidebarState();
//   return (
//     <>
//       <Menu {...props}>
//         {/* <Box mt={2} ml={1}>
//           <h3>CollegeTpoint</h3> */}
//         {Object.keys(resources).map((name) => (
//           <MenuItemLink
//             key={name}
//             to={`/${name}`}
//             primaryText={
//               (resources[name].options && resources[name].options.label) || name
//             }
//             leftIcon={createElement(resources[name].icon)}
//             onClick={onMenuClick}
//             sidebarIsOpen={open}
//           />
//         ))}
//         {permissions === "admin" || permissions === "editor" ? (
//           <MenuItemLink
//             to="/images"
//             primaryText="Images"
//             leftIcon={<LabelIcon />}
//             onClick={onMenuClick}
//             sidebarIsOpen={open}
//           />
//         ) : null}
//         {permissions === "admin" || permissions === "editor" ? (
//           <MenuItemLink
//             to="/carousels"
//             primaryText="Carousel"
//             leftIcon={<ViewCarouselOutlinedIcon />}
//             onClick={onMenuClick}
//             sidebarIsOpen={open}
//           />
//         ) : null}
//         {isXSmall && logout}
//         {/* </Box> */}
//       </Menu>
//     </>
//   );
// };

// export default MenuSidebar;
