import React from "react";
import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PostTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const CutoffJsonEdit = (props) => {
  return (
    <>
      <h2>Edit Cutoff JSON Data</h2>
      <Edit {...props} title={<PostTitle />}>
        <SimpleForm title="Edit Blog">
          <TextInput source="institute_id" required fullWidth />
          <TextInput source="title" fullWidth />
          <TextInput source="description" fullWidth />
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>2023 Data</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: "100%" }}>
              <TextInput source="json_data_2023" fullWidth multiline />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>2022 Data</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: "100%" }}>
              <TextInput source="json_data_2022" fullWidth multiline />
            </AccordionDetails>
          </Accordion>
          {/* Repeat the above Accordion section for other years' data */}
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>2021 Data</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: "100%" }}>
              <TextInput source="json_data_2021" fullWidth multiline />
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>2020 Data</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: "100%" }}>
              <TextInput source="jsonData" fullWidth multiline />
            </AccordionDetails>
          </Accordion>
        </SimpleForm>
      </Edit>
    </>
  );
};

// import React from "react";
// import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";

// const PostTitle = ({ record }) => {
//   return <span>{record ? `${record.title}` : ""}</span>;
// };

// export const CutoffJsonEdit = (props) => {
//   return (
//     <>
//       <h2>Edit Cutoff JSON Data</h2>
//       <Edit {...props} title={<PostTitle />}>
//         <SimpleForm title="Edit Blog">
//           <TextInput source="institute_id" required fullWidth />
//           <TextInput source="title" fullWidth />
//           <TextInput source="description" fullWidth />
//           <TextInput source="json_data_2022" fullWidth multiline />
//           <TextInput source="json_data_2021" fullWidth multiline />
//           <TextInput source="jsonData" fullWidth multiline />
//         </SimpleForm>
//       </Edit>
//     </>
//   );
// };
