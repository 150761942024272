import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  List,
} from "react-admin";

export const PdfFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by title" source="title" alwaysOn />
  </Filter>
);
