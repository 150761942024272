import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ImageField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { PartialTextField } from "../../components/common/PartialTextField";

import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  Divider,
  ListItemText,
  Button,
  Dialog,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    "& img": {
      maxHeight: "5rem",
    },
  },
});

export const NotificationList = ({ permissions, ...props }) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      exporter={false}
      //   filters={<UserFilter permissions={permissions} />}
      // pagination={permissions === "admin" ? true : false}
      // perPage={15}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={permissions === "admin" ? true : false}
      >
        <TextField label="Id" name={"id"} source="id" />
        <TextField label="Title" name={"title"} source="title" />
        <TextField name={"short_description"} source="short_description" />
        <PartialTextField
          maxLength={200}
          style={{ width: 250 }}
          name={"details"}
          source="details"
        />
        <ImageField
          className={classes.root}
          label="Image URL"
          name={"img_url"}
          source="img_url"
        />
        <TextField name={"cta"} source="cta" />

        <ArrayField source="groups" label="Categories">
          <SingleFieldList>
            <ChipField source="title" color="secondary" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="tags" label="Tags">
          <SingleFieldList>
            <ChipField source="title" color="default" />
          </SingleFieldList>
        </ArrayField>
        <TimeField label="Created" name={"Created on"} source="createdAt" />
        <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
      </Datagrid>
    </List>
  );
};
