import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  NumberField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import SubscriptionFilter from "./SubscriptionFilter";
import { CustomActiveField } from "../DyanamicLayout/DyanamicLayoutList";

import { UserInfo } from "../../components/common/UserInfo";

export const SubscriptionList = (props) => (
  <List
    {...props}
    filters={<SubscriptionFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false}>
      <TextField label="Id" source="id" />
      <UserInfo sortable={false} {...props} label="User Id" source="user_id" />
      {/* <UserInfo sortable={false} {...props} label="User Email" source="" /> */}
      {/* <TextField label="User Id" source="user_id" /> */}
      <TextField label="Plan Id" source="plan_id" />
      <TextField label="Status" source="status" />
      <TextField label="Is Active" source="active" />
      <TextField label="Order Id" source="order_id" />
      <TextField label="Receipt Id" source="receipt_id" />

      <CustomActiveField
        label="Invoice"
        name={"invoice_sent"}
        source="invoice_sent"
      />
      <TextField label="Invoice Link" source="invoice_link" />

      <DateField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
      <DateField label="Expired On" source="expiry_date" />
    </Datagrid>
  </List>
);
