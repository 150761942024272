import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  List,
} from "react-admin";

const SubscriptionQueryFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search by UserId" source="user_id" alwaysOn />
    <TextInput label="Search by OrderId" source="order_id" alwaysOn />
    <TextInput label="Search by ReceiptId" source="receipt_id" alwaysOn />
    <TextInput label="Search by PlanId" source="plan_id" alwaysOn />
  </Filter>
);

export default SubscriptionQueryFilter;
