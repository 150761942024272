import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  RichTextField,
  SelectInput,
} from "react-admin";
import { IS_DOWNLOADABLE } from "./PdfCreate";

const PostTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const PdfEdit = (props) => {
  return (
    <>
      <h2>Edit Pdf</h2>
      <Edit {...props} title={<PostTitle />}>
        <SimpleForm title="Edit Pdf">
          <TextInput source="title" fullWidth />
          <TextInput source="url" fullWidth />
          <SelectInput
            source="download"
            choices={IS_DOWNLOADABLE}
            optionText="name"
            optionValue="value"
            required
          />
        </SimpleForm>
      </Edit>
    </>
  );
};
