import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ImageField,
  UrlField,
  EditButton,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { NewsFilter } from "../index";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SendDialog from "./SendDialog";
import { PartialTextField } from "../../components/common/PartialTextField";

const useStyles = makeStyles({
  root: {
    display: "flex",
    "& img": {
      maxHeight: "5rem",
    },
  },
});

export const NewsList = (props) => {
  const classes = useStyles();

  const handleSendNotification = () => {
    console.log("senddd", props);
  };
  return (
    <>
      <List
        {...props}
        filters={<NewsFilter />}
        exporter={false}
        hasList={false}
        sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <TextField label="Id" name={"id"} source="id" />
          <TextField label="Title" name={"title"} source="title" />
          <PartialTextField
            maxLength={200}
            style={{ width: 300 }}
            label="Description"
            name={"description"}
            source="description"
          />
          <TextField label="Source" name={"source"} source="source" />
          {/* <UrlField label="Source URL" name={"source_url"} source="source_url" /> */}
          <ImageField
            className={classes.root}
            label="Image URL"
            name={"image"}
            source="image"
          />
          {/* <EditButton /> */}
          {/* <TextField label="Video URL" name={"video"} source="video" /> */}
          {/* <TimeField label="Created" name={"Created on"} source="createdAt" /> */}
          <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
        </Datagrid>
      </List>
    </>
  );
};
