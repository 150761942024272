import React from "react";
import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";

const PostTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const SeatmatrixEdit = (props) => {
  return (
    <>
      <h2>Edit Seat Matrix</h2>
      <Edit {...props} title={<PostTitle />}>
        <SimpleForm title="Edit Seat Matrix">
          <TextInput source="institute_id" required fullWidth />
          <TextInput source="title" fullWidth />
          <TextInput source="description" fullWidth />
          <TextInput source="jsonData" required fullWidth multiline />
        </SimpleForm>
      </Edit>
    </>
  );
};
