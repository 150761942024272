import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
  CheckboxGroupInput,
  BooleanInput,
  RadioButtonGroupInput,
  useCreate,
  useNotify,
  useRedirect,
} from "react-admin";
import dataProvider from "../../utils/apiService";

export const CallToAction = (props) => {
  const { show, title, type, redirect } = props.value;
  const { handleAction } = props;
  //   const [callToAction, setCallToAction] = useState({
  //     show: true,
  //     title: "",
  //     type: "",
  //     redirect: "",
  //   });

  console.log("callToAction", props);
  const toggleShow = () => {
    console.log("clicked");
    handleAction({
      ...props.value,
      show: !show,
    });
  };

  return (
    <>
      <BooleanInput
        source="show"
        label="Show Button"
        defaultValue={show}
        parse={(v) => v}
        // value={show}
        onClick={toggleShow}
      />
    </>
  );
};
export const NotificationCreate = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [tagData, setTagData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const loadCategories = () => {
    dataProvider
      .get(`notification-categories`)
      .then((res) => {
        // console.log(res);
        setCategoryData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const loadTags = () => {
    dataProvider
      .get(`notification-tags`)
      .then((res) => {
        // console.log(res);
        setTagData(res.data);
        // setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadCategories();
    loadTags();
  }, []);

  const [callToAction, setCallToAction] = useState({
    show: true,
    title: "",
    type: "",
    redirect: "",
  });

  const [notificationType, setNotificationType] = useState("NEWS");
  const [showRedirectUrlInput, setShowRedirectUrlInput] = useState(true);

  const toggleShowRedirectUrlInput = () => {
    setShowRedirectUrlInput(!showRedirectUrlInput);
  };

  //   const handleAction = (action) => {
  //     console.log("action", action);
  //     setCallToAction(action);
  //   };
  console.log("notificationType", notificationType);
  const [create, { data, isSuccess }] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();
  const saveData = (data) => {
    if (data.categories === undefined || data.categories.length === 0) {
      return alert("CATEGORY MISSING !! Please select at least one category");
    }
    if (data.tags === undefined || data.tags.length === 0) {
      return alert("TAG MISSING !! Please select at least one tag");
    }
    create(
      "notifications/filtered",
      { data },
      {
        onSuccess: (data) => {
          // success side effects go here
          redirect("/notifications/filtered");
          notify("Notification Added Successfully", "success");
        },
        onError: (error) => {
          // failure side effects go here
          notify(`Notification save error: ${error.message}`, {
            type: "warning",
          });
        },
      }
    );

    // console.log("data", data);
  };

  return (
    <>
      <h1>New Notification</h1>
      <Create {...props} title="Create A New Notification">
        <SimpleForm title="New Notification" onSubmit={saveData}>
          <TextInput source="title" label="Title" required fullWidth />
          <TextInput
            source="short_description"
            label="Short Description"
            fullWidth
          />
          <TextInput source="details" label="Details" fullWidth />
          <TextInput source="img_url" label="Image URL" fullWidth />

          {/* <CallToAction
            source="cta"
            label="Call To Action"
            value={callToAction}
            handleAction={handleAction}
            required
            fullWidth
          />
          <TextInput source="cta" label="Call To Action" required fullWidth /> */}

          <RadioButtonGroupInput
            source="cta.type"
            label="Notification Type"
            defaultValue={"NEWS"}
            choices={[
              { id: "NEWS", name: "NEWS" },
              { id: "OTHER", name: "OTHER" },
            ]}
            onChange={(e) => {
              setNotificationType(e.target.value);
            }}
          />

          {notificationType !== "NEWS" && (
            <>
              <BooleanInput
                source="cta.show"
                label="Show Redirect Button"
                defaultValue={true}
                onChange={toggleShowRedirectUrlInput}
                // parse={(v) => v}
                // // value={show}
                // onClick={toggleShow}
              />
              {showRedirectUrlInput && (
                <>
                  <TextInput
                    source="cta.redirect"
                    label="Redirect URL"
                    required
                    fullWidth
                  />
                  <TextInput
                    // defaultValue={"Read News"}
                    source="cta.title"
                    label="Redirect Button Label"
                    required
                  />
                </>
              )}
            </>
          )}
          {notificationType === "NEWS" && (
            <>
              <TextInput source="news_source" label="News Source" required />
              <TextInput
                source="news_source_link"
                label="News Source Link"
                // required
                fullWidth
              />
            </>
          )}

          <CheckboxGroupInput
            source="categories"
            choices={categoryData}
            optionText="title"
            optionValue="category_id"
            isRequired={true}
          />
          <CheckboxGroupInput
            source="tags"
            choices={tagData}
            optionText="title"
            optionValue="tag_id"
            isRequired={true}
          />

          {/* <TextInput source="categories" label="Category" required fullWidth /> */}
          {/* <TextInput source="tags" label="Tags" required fullWidth disabled /> */}
        </SimpleForm>
      </Create>
    </>
  );
};
