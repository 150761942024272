import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
} from "react-admin";

export const IS_DOWNLOADABLE = [
  {
    name: "YES",
    value: true,
  },
  {
    name: "NO",
    value: false,
  },
];

export const PdfCreate = (props) => {
  return (
    <>
      <h1>New Pdf</h1>
      <Create {...props} title="Create A New Pdf">
        <SimpleForm title="New Pdf">
          <TextInput source="title" fullWidth />
          <TextInput source="url" fullWidth />
          <SelectInput
            source="download"
            choices={IS_DOWNLOADABLE}
            optionText="name"
            optionValue="value"
            required
          />
        </SimpleForm>
      </Create>
    </>
  );
};
