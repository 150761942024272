import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
} from "react-admin";

export const SeatmatrixCreate = (props) => {
  return (
    <>
      <h1>New Seat Matrix Json</h1>
      <Create {...props} title="Create A New Seatmatrix Json">
        <SimpleForm title="New Seat Matrix Json">
          <TextInput source="institute_id" required fullWidth />
          <TextInput source="title" fullWidth />
          <TextInput source="description" fullWidth />
          <TextInput source="jsonData" required fullWidth multiline />
        </SimpleForm>
      </Create>
    </>
  );
};
