import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  List,
} from "react-admin";

export const UserFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
    <TextInput label="Search by Email" source="email" alwaysOn />
    <TextInput label="Search by Phone" source="phone" alwaysOn />
    {permissions === "admin" && (
      <TextInput label="Search by UserId" source="user_id" alwaysOn />
    )}
    {permissions === "admin" && (
      <TextInput label="Search by Attributes" source="attributes" alwaysOn />
    )}
  </Filter>
);
