import React from "react";
import { Edit, SimpleForm, TextInput, RichTextField } from "react-admin";

const NotificationCategoryTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const NotificationCategoryEdit = (props) => {
  return (
    <>
      <h2>Edit Notification Category</h2>
      <Edit {...props} title={<NotificationCategoryTitle />}>
        <SimpleForm title="Edit Notification Category">
          <TextInput
            source="category_id"
            label="Category Id"
            required
            fullWidth
          />
          <TextInput source="title" required fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};
