import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";

export const NotificationCategoryList = (props) => (
  <List {...props} sort={{ field: "id", order: "DESC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField
        label="Category Id"
        name={"category_id"}
        source="category_id"
      />
      <TextField label="Title" name={"title"} source="title" />
      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
