import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  useRecordContext,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { CouponFilter } from "./CouponFilter";
import moment from "moment";

const CustomTimeField = ({ source }) => {
  const record = useRecordContext();
  const isValid = moment(record[source]) > moment();
  return (
    <div
      style={{
        backgroundColor: isValid ? "#a5efa5" : "#ff00008f",
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 2,
        paddingBottom: 2,

        borderRadius: 6,
      }}
    >
      {moment(record[source]).fromNow()}
    </div>
  );
};

export const CouponList = (props) => (
  <List
    {...props}
    filters={<CouponFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <NumberField label="Id" name={"id"} source="id" />
      <TextField label="Name" name={"coupon_name"} source="coupon_name" />
      <TextField label="Code" name={"coupon_code"} source="coupon_code" />
      <TextField
        label="Coupon Type"
        name={"coupon_type"}
        source="coupon_type"
      />
      <TextField
        label="Discount Type"
        name={"discount_type"}
        source="discount_type"
      />
      <TextField label="Discount" name={"discount"} source="discount" />
      <TextField label="Status" name={"status"} source="status" />
      <TextField
        label="Subscription Plan"
        name={"subscription_plan"}
        source="subscription_plan"
      />
      <TextField label="Use Count" name={"count"} source="count" />
      <TextField label="Approved" name={"approved"} source="approved" />
      <TextField label="Created By" name={"user_id"} source="user_id" />

      <TimeField label="Start Date" name={"start_date"} source="start_date" />
      <CustomTimeField label="End Date" name={"end_date"} source="end_date" />

      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
