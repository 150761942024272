import React from "react";
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";

const TermsTitle = ({ record }) => {
  return <span>{record ? `${record.name} (${record.role})` : ""}</span>;
};

export const TermsEdit = (props) => {
  return (
    <Edit {...props} title={<TermsTitle />}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="title" />
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};
