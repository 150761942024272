import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  PasswordInput,
  NumberInput,
  DateTimeInput,
} from "react-admin";

export const COUPON_TYPES = [
  {
    name: "ONE_TIME",
    value: "ONE_TIME",
  },
  {
    name: "ONE_DAY",
    value: "ONE_DAY",
  },
  {
    name: "CUSTOM",
    value: "CUSTOM",
  },
];

export const DISCOUNT_TYPES = [
  {
    name: "AMOUNT",
    value: "AMOUNT",
  },
  {
    name: "PERCENTAGE",
    value: "PERCENTAGE",
  },
];
export const STATUS = [
  {
    name: "ACTIVE",
    value: "ACTIVE",
  },
  {
    name: "EXPIRED",
    value: "EXPIRED",
  },
  {
    name: "DRAFT",
    value: "DRAFT",
  },
];

export const CouponCreate = (props) => {
  return (
    <>
      <h1>New Coupon</h1>
      <Create {...props} title="Create A New Coupon">
        <SimpleForm title="New Coupon">
          <TextInput source="coupon_name" required fullWidth />
          <SelectInput
            source="coupon_type"
            choices={COUPON_TYPES}
            optionText="name"
            optionValue="value"
            required
          />
          {/* <TextInput source="coupon_type" fullWidth /> */}
          <TextInput source="coupon_code" required fullWidth />
          <SelectInput
            source="discount_type"
            choices={DISCOUNT_TYPES}
            optionText="name"
            optionValue="value"
            required
          />
          {/* <TextInput source="discount_type" fullWidth /> */}
          <TextInput source="discount" fullWidth />
          <SelectInput
            source="status"
            choices={STATUS}
            optionText="name"
            optionValue="value"
            required
          />
          {/* <TextInput source="status" fullWidth /> */}
          <TextInput source="subscription_plan" fullWidth />
          <DateTimeInput source="start_date" fullWidth />
          <DateTimeInput source="end_date" fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};
