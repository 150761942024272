import React from "react";
import {
  useAuthenticated,
  List,
  useResourceContext,
  ResourceContextProvider,
} from "react-admin";

const ResourceName = () => {
  const { resource } = useResourceContext();
  return <>{resource}</>;
};

// const PostList = (props) => (
//   <List {...props}>
//       <>
//           <ResourceName /> {/* renders 'posts' */}
//           <Datagrid>
//               <TextField source="title" />
//               <DateField source="published_at" />
//           </Datagrid>
//       <>
//   </List>
// )

const SendNotification = () => {
  useAuthenticated();
  return (
    <div>
      Custom page for sending notification{" "}
      <ResourceContextProvider value="user-info">
        <ResourceName />
      </ResourceContextProvider>
    </div>
  );
};

export default SendNotification;
