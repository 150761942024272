import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  RichTextField,
  DateTimeInput,
  useRecordContext,
  SelectInput,
  usePermissions,
} from "react-admin";
import { LAYOUT_TYPES, STATUS } from "./DyanamicLayoutCreate";

const RecordTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.site} (${record.layout_type})` : ""}</span>;
};

export const DyanamicLayoutEdit = (props) => {
  const { permissions } = usePermissions();
  console.log("permissions", permissions);
  return (
    <>
      <h2>Edit Dyanamic Layout</h2>
      <Edit {...props} title={<RecordTitle />} action>
        <SimpleForm title="Edit Dyanamic Layout">
          <SelectInput
            source="layout_type"
            choices={LAYOUT_TYPES}
            optionText="name"
            optionValue="value"
            required
            disabled={permissions === "admin" ? false : true}
          />
          <TextInput
            source="site"
            required
            fullWidth
            disabled={permissions === "admin" ? false : true}
          />
          <TextInput source="data" fullWidth multiline />
          <SelectInput
            source="active"
            choices={STATUS}
            optionText="name"
            optionValue="value"
            required
          />
        </SimpleForm>
      </Edit>
    </>
  );
};
