import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ImageField,
  useRecordContext,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { CollegeFilter } from "../index";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    "& img": {
      maxHeight: "5rem",
    },
  },
});

const LogoField = (props) => {
  const classes = useStyles();
  const record = useRecordContext();
  return (
    <div className={classes.root}>
      <img
        // alt="no logo img"
        src={`https://collegetpoint.sgp1.digitaloceanspaces.com/College_logos/College_logos/${record.institute_id}.png`}
      />
    </div>
  );
};

export const CollegeList = (props) => {
  const classes = useStyles();
  // console.log("PROPS::", props);
  return (
    <List
      {...props}
      filters={<CollegeFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField
          label="Inst. Id"
          name={"institute_id"}
          source="institute_id"
        />
        <TextField
          label="Inst. Name"
          name={"institute_name"}
          source="institute_name"
        />
        <TextField label="Search" name={"search"} source="search" />
        <TextField label="State" name={"stateId"} source="stateId" />
        <TextField label="Type" name={"type"} source="type" />
        <TextField label="Rank" name={"rank"} source="rank" />
        <LogoField label="Logo" />
        <ImageField
          className={classes.root}
          label="Img Url"
          name={"img_url"}
          source="img_url"
        />
        <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
      </Datagrid>
    </List>
  );
};
