import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ImageField,
  useRecordContext,
  Button,
  usePermissions,
  Pagination,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { UserFilter } from "../index";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListItem,
  Divider,
  ListItemText,
  // Button,
  Dialog,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    "& img": {
      maxHeight: "5rem",
    },
  },
});

export const CollegesVisited = ({ source }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  if (record[source] && !record[source].length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record[source].map((item, index) => (
        <>
          {index < 2 && (
            <>
              <div>
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "0.1px solid #ffffff33",
                    borderRadius: "6px",
                    marginTop: 4,
                  }}
                >
                  <div style={{ fontSize: 12 }}>{item.institute_name}</div>
                  <div
                    style={{
                      background: "#fff",
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 16,
                      borderRadius: 24,
                      paddingLeft: 7,
                      paddingRight: 7,
                    }}
                  >
                    {item.count}
                  </div>
                </ListItem>
              </div>
            </>
          )}
        </>
      ))}
      {record[source].length > 2 && (
        <Button
          variant="text"
          style={{ fontSize: 8 }}
          color="primary"
          onClick={toggleModal}
        >
          + Show More
        </Button>
      )}
      <Dialog open={open} onClose={toggleModal}>
        {record[source].map((item, index) => (
          <>
            <div>
              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: 14 }}>{`${index + 1}.  ${
                  item.institute_name
                } (${item.institute_id})`}</div>
                <div
                  style={{
                    background: "#fff",
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: 16,
                    borderRadius: 24,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  {item.count}
                </div>
              </ListItem>
              <Divider />
            </div>
            <Divider />
          </>
        ))}
      </Dialog>
    </>
  );
};

export const Subscriptions = ({ source }) => {
  const record = useRecordContext();
  if (record[source] && !record[source].length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record[source].map((item, index) => (
        <div>
          <ListItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              border: "0.1px solid orange",
              borderRadius: "8px",
            }}
          >
            <div style={{ fontSize: 12 }}>{item.plan_id}</div>
          </ListItem>
        </div>
      ))}
    </>
  );
};

export const SubscriptionQuery = ({ source }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  if (record[source] && !record[source].length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record[source].map((item, index) => (
        <>
          {index < 2 && (
            <>
              <div>
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "0.1px solid #ffffff33",
                    borderRadius: "6px",
                    marginTop: 4,
                  }}
                >
                  <div
                    style={{ fontSize: 12 }}
                  >{`(${item.type}) : ${item.query}`}</div>
                  <div
                    style={{
                      background: "#fff",
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 16,
                      borderRadius: 24,
                      paddingLeft: 7,
                      paddingRight: 7,
                    }}
                  >
                    {item.resolved ? "Yes" : "No"}
                  </div>
                </ListItem>
              </div>
            </>
          )}
        </>
      ))}
      {record[source].length > 2 && (
        <Button
          variant="text"
          style={{ fontSize: 8 }}
          color="primary"
          onClick={toggleModal}
        >
          + Show More
        </Button>
      )}
      <Dialog open={open} onClose={toggleModal}>
        {record[source].map((item, index) => (
          <>
            <div>
              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "0.1px solid #ffffff33",
                }}
              >
                <div style={{ fontSize: 14 }}>{`${index + 1}.  (${
                  item.type
                }) : ${item.query}`}</div>
                <div
                  style={{
                    background: "#fff",
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: 16,
                    borderRadius: 24,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  {item.resolved ? "Yes" : "No"}
                </div>
              </ListItem>
            </div>
            <Divider />
          </>
        ))}
      </Dialog>
    </>
  );
};

export const ContactUsQuery = ({ source }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  if (record[source] && !record[source].length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record[source].map((item, index) => (
        <>
          {index < 2 && (
            <>
              <div>
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "0.1px solid #ffffff33",
                    borderRadius: "6px",
                    marginTop: 4,
                  }}
                >
                  <div
                    style={{ fontSize: 12 }}
                  >{`(${item.type}) : ${item.message}`}</div>
                  <div
                    style={{
                      background: "#fff",
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 16,
                      borderRadius: 24,
                      paddingLeft: 7,
                      paddingRight: 7,
                    }}
                  >
                    {item.resolved ? "Yes" : "No"}
                  </div>
                </ListItem>
              </div>
            </>
          )}
        </>
      ))}
      {record[source].length > 2 && (
        <Button
          variant="text"
          style={{ fontSize: 8 }}
          color="primary"
          onClick={toggleModal}
        >
          + Show More
        </Button>
      )}
      <Dialog open={open} onClose={toggleModal}>
        {record[source].map((item, index) => (
          <>
            <div>
              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "0.1px solid #ffffff33",
                }}
              >
                <div style={{ fontSize: 14 }}>{`${index + 1}.  (${
                  item.type
                }) : ${item.message}`}</div>
                <div
                  style={{
                    background: "#fff",
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: 16,
                    borderRadius: 24,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  {item.resolved ? "Yes" : "No"}
                </div>
              </ListItem>
            </div>
            <Divider />
          </>
        ))}
      </Dialog>
    </>
  );
};

export const CollegesReviewed = ({ source }) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  if (record[source] && !record[source].length) {
    return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;
  }
  return (
    <>
      {record[source].map((item, index) => (
        <>
          {index < 2 && (
            <>
              <div>
                <ListItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "0.1px solid #ffffff33",
                    borderRadius: "6px",
                    marginTop: 4,
                  }}
                >
                  <div
                    style={{ fontSize: 12 }}
                  >{`(${item.instituteName}) :: ${item.review}`}</div>
                  <div
                    style={{
                      background: "#fff",
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 16,
                      borderRadius: 24,
                      paddingLeft: 7,
                      paddingRight: 7,
                    }}
                  >
                    {item.starCount}
                  </div>
                </ListItem>
              </div>
            </>
          )}
        </>
      ))}
      {record[source].length > 2 && (
        <Button
          variant="text"
          style={{ fontSize: 8 }}
          color="primary"
          onClick={toggleModal}
        >
          + Show More
        </Button>
      )}
      <Dialog open={open} onClose={toggleModal}>
        {record[source].map((item, index) => (
          <>
            <div>
              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "0.1px solid #ffffff33",
                }}
              >
                <div style={{ fontSize: 14 }}>{`${index + 1}.  (${
                  item.instituteName
                }) :: ${item.review}`}</div>
                <div
                  style={{
                    background: "#fff",
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: 16,
                    borderRadius: 24,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  {item.starCount}
                </div>
              </ListItem>
            </div>
            <Divider />
          </>
        ))}
      </Dialog>
    </>
  );
};

export const UserList = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  return (
    <List
      {...props}
      exporter={false}
      filters={<UserFilter permissions={permissions} />}
      sort={{ field: "id", order: "DESC" }}
      pagination={permissions === "admin" ? <Pagination /> : null}
      // pagination={permissions === "admin" ? true : false}
      // perPage={15}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField label="User Info Id" name={"Id"} source="id" />
        <TextField name={"Name"} source="name" />
        <EmailField label="Email address" name={"Email"} source="email" />
        <NumberField label="Phone" source="phone" />
        <TextField label="Attributes" source="attributes" />
        <TextField label="User Id" source="user_id" />
        <TextField label="Has Rated" source="rated" />
        <TextField label="Rating Feedback" source="rating_feedback" />
        <ImageField
          className={classes.root}
          label="Image URL"
          name={"img_url"}
          source="img_url"
        />
        <TimeField label="Created" name={"Created on"} source="createdAt" />
        <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
        <CollegesVisited
          sortable={false}
          label="Colleges Visited"
          source="collegesVisited"
        />
        <Subscriptions
          sortable={false}
          label="Subscriptions"
          source="subscribedPlans"
        />
        <SubscriptionQuery
          sortable={false}
          label="Subscription Query"
          source="subscriptionQuery"
        />
        <ContactUsQuery
          sortable={false}
          label="Contact Us Query"
          source="userQuery"
        />
        <CollegesReviewed
          sortable={false}
          label="Colleges Reviewed"
          source="collegesReviewed"
        />
      </Datagrid>
    </List>
  );
};

// export default UserList;
