import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  useRecordContext,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { PartialTextField } from "../../components/common/PartialTextField";
import { DyanamicLayoutFilter } from "./DyanamicLayoutFilter";
import moment from "moment";

export const CustomActiveField = ({ source }) => {
  const record = useRecordContext();
  const isValid = record[source] === true;
  return (
    <div
      style={{
        backgroundColor: isValid ? "#a5efa5" : "#ff00008f",
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 2,
        paddingBottom: 2,

        borderRadius: 6,
      }}
    >
      {record[source] ? "Yes" : "No"}
    </div>
  );
};

export const DyanamicLayoutList = (props) => (
  <List
    {...props}
    filters={<DyanamicLayoutFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <NumberField label="Id" name={"id"} source="id" />
      <TextField
        label="Layout Type"
        name={"layout_type"}
        source="layout_type"
      />
      <TextField label="Site" name={"site"} source="site" />
      <PartialTextField
        maxLength={200}
        style={{ width: 300 }}
        label="Data"
        name={"data"}
        source="data"
      />

      <CustomActiveField label="Is Active" name={"active"} source="active" />

      <TimeField label="Created" name={"Created on"} source="createdAt" />
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
