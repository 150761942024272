import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
} from "react-admin";
import { TimeField } from "../../components/common/TimeField";
import { GeneralQueryFilter } from "../index";

export const GeneralQueryList = (props) => (
  <List
    {...props}
    filters={<GeneralQueryFilter />}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField label="Name" name={"name"} source="name" />
      <TextField label="Email" name={"email"} source="email" />
      <TextField label="Phone" name={"phone"} source="phone" />
      {/* <TimeField label="Created" name={"Created on"} source="createdAt" /> */}
      <TimeField label="Updated" name={"Updated on"} source="updatedAt" />
    </Datagrid>
  </List>
);
