// In theme.js
import { defaultTheme } from "react-admin";
import { createTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";

export const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      // Your theme goes here
      // Write the following code to have an orange app bar. We'll explain it later in this article.
      // secondary: {
      //   // main: "#8c8c8c",
      //   main: "#e83f7d", // Not far from orange
      // },
      // primary: {
      //   // main: "#8c8c8c",
      //   main: "#f5663b", // Not far from orange
      // },
      primary: {
        main: "#1090af",
      },
      action: {
        main: "#1090af",
      },
      secondary: {
        main: "#0e6caf",
        // main: "#00bcd4",
      },
      type: "light",
    },
  })
);
