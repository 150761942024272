import React from "react";
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  List,
} from "react-admin";

const SubscriptionQueryFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search by UserId" source="user_id" alwaysOn />
    <TextInput label="Search by Resolved (0/1)" source="resolved" alwaysOn />
    <TextInput label="Search by Phone" source="phone" alwaysOn />
    <TextInput label="Search by OrderId" source="order_id" alwaysOn />
    <TextInput label="Search by PaymentId" source="payment_id" alwaysOn />
  </Filter>
);

export default SubscriptionQueryFilter;
