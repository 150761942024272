import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  RichTextField,
  CheckboxGroupInput,
  useRecordContext,
  useEditController,
} from "react-admin";
import SendDialog from "./SendAppNotificationDialog";
import Box from "@material-ui/core/Box";

const NotificationTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.title}` : ""}</span>;
};

export const NotificationEdit = (props) => {
  const { record, save, isLoading } = useEditController({
    resource: "notifications/filtered",
    id: props.id,
  });
  if (isLoading) return null;
  console.log("props::::", record);
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <h2>Edit Notification</h2>
        </div>
        <div>
          <SendDialog data={record} />
        </div>
      </Box>
      <Edit {...props} title={<NotificationTitle />}>
        <SimpleForm title="Edit Notification Category">
          <TextInput source="title" label="Title" required fullWidth />
          <TextInput source="short_description" required fullWidth />
          <TextInput source="details" required fullWidth />
          <TextInput source="img_url" required fullWidth />
          <TextInput source="cta" required fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};
