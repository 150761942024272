import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { AppSettings } from "../config/appSettings";
import axios from "axios";

// const apiUrl = `${AppSettings.server.baseUrl}`;
const apiUrl = `${AppSettings.server.baseUrl}/api/v1`;

const httpClient = fetchUtils.fetchJson;
const httpAxiosClient = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const dataProvider = {
  getList: (resource, params) => {
    console.log("QQQQ:", resource, params);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const updatedOrder = order === "ASC" ? field : `-${field}`;
    let filter = "";
    // filter[institute_id][like]=%COM%
    if (Object.keys(params.filter).length > 0) {
      const searchKey = Object.keys(params.filter)[0];
      const searchVal = params.filter[searchKey];
      filter = `filter[${searchKey}][like]=%${searchVal}%`;
    }

    const url = `${apiUrl}/${resource}?${filter}&sort=${updatedOrder}&page=${page}&limit=${perPage}`;
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);

    return httpClient(url, options).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.count,
      };
    });
  },

  getOne: (resource, params) => {
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(
      ({ json }) => ({
        data: json.data,
      })
    );
  },

  get: (resource, params) => {
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);
    let param = "";
    if (params && params.id) {
      param = `/${params.id}`;
    }
    return httpClient(`${apiUrl}/${resource}${param}`, options).then(
      ({ json }) => ({
        data: json.data,
      })
    );
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    // const url = `${apiUrl}/${resource}?${stringify(query)}`;
    let filter = `filter[id][in]=[${[params.ids]}]`;
    const url = `${apiUrl}/${resource}?${filter}`;

    console.log("URL:", url);

    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);

    return httpClient(url, options).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.count,
      };
    });
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);

    return httpClient(url, options).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource, params) => {
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);
    options.method = "PUT";
    options.body = JSON.stringify(params.data);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(
      ({ json }) => {
        return { data: json.data };
      }
    );
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);
    options.method = "POST";
    options.body = JSON.stringify(params.data);
    return httpClient(`${apiUrl}/${resource}`, options).then(({ json }) => {
      return {
        data: { ...params.data, id: json.id },
      };
    });
  },

  post: (resource, params) => {
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);
    options.method = "POST";
    options.body = JSON.stringify(params.data);
    return httpClient(`${apiUrl}/${resource}`, options).then(({ json }) => {
      return {
        data: { ...params.data },
      };
    });
  },

  upload: (resource, file, onUploadProgress, params) => {
    let options = {};
    const token = localStorage.getItem("token");

    if (params && params.data) {
      options.body = JSON.stringify(params.data);
    }

    let formData = new FormData();

    formData.append("file", file);

    return httpAxiosClient.post(resource, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress,
    });
  },

  delete: (resource, params) => {
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);
    options.method = "DELETE";
    return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(
      ({ json }) => ({ data: json.data })
    );
  },

  deleteMany: (resource, params) => {
    let options = {};
    const token = localStorage.getItem("token");
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Authorization", `Bearer ${token}`);
    options.body = JSON.stringify({ ids: params.ids });
    options.method = "DELETE";

    return httpClient(`${apiUrl}/${resource}?ids=${params.ids}`, options).then(
      ({ json }) => ({ data: json.data })
    );
  },
};

export default dataProvider;
